import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import {
  useGetAllCategoriesQuery,
  useGetSlidersQuery,
} from "../../redux/feature/slice/service-api";
import { Link } from "react-router-dom";
import CategoriesSkeletonLoader from "../loading/categoriesDropdownSkeletonLoader";
import BannerSkeletonLoader from "../loading/bannerSkeletonLoader";
import { MdVerifiedUser } from "react-icons/md";
import { IoIosPricetags } from "react-icons/io";

const Banner = () => {
  const { data: Categories, isLoading: categoryLoading } =
    useGetAllCategoriesQuery();
  const { data: slider, isLoading } = useGetSlidersQuery();
  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const showDropdown = (categoryName) => {
    setVisibleDropdown(categoryName);
  };

  const hideDropdown = () => {
    setVisibleDropdown(null);
  };

  return (
    <>
      <section className="container mx-auto px-2 lg:px-20 mt-14 md:mt-0">
        <div className="lg:flex flex-col md:flex-row items-start mt-4 gap-4">
          {/* <!-- banner dropdown --> */}
          <div className="relative w-64">
            <div
              onMouseLeave={hideDropdown}
              className="w-full hidden lg:block overflow-y-scroll overflow-x-hidden rounded-lg bg-white h-60 xl:min-h-80 2xl:h-96"
            >
              <ul className="">
                {categoryLoading && <CategoriesSkeletonLoader />}

                {Categories?.data?.map((c) => (
                  <div key={c?.id} onMouseEnter={() => showDropdown(c?.name)}>
                    <button
                      id="dropdownRightButton"
                      data-dropdown-trigger="hover"
                      data-dropdown-toggle={`${c?.name}`}
                      data-dropdown-placement="right"
                      className="text-black flex p-1 gap-4 items-center text-left my-2 hover:text-primary"
                    >
                      <span>
                        <img
                          src={c?.imageUrl}
                          alt={c?.name}
                          className="w-8 md:w-10"
                        />
                      </span>
                      <p className="w-32">{c?.name}</p>
                      <svg
                        className="w-2.5 h-2.5"
                        aria-hidden={true}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                    </button>
                    {/* <!-- dropdown menu --> */}
                    <div
                      id={`${c?.name}`}
                      className={`${
                        visibleDropdown === c?.name ? "block" : "hidden"
                      } bg-white rounded-lg shadow py-2 px-4 absolute left-full top-0 w-52 z-20 h-60 xl:h-80 2xl:h-96`}
                    >
                      <ul className="py-2 text-base_700 px-4">
                        {c?.subcategories?.map((sub) => (
                          <li key={sub.id} className="my-2">
                            <Link
                              to={`/categories-products/${sub?.name}/${sub.id}`}
                            >
                              {sub.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
          {/* <!-- banner slider --> */}

          <div className="w-full lg:w-[600px] xl:w-4/5 mx-auto">
            <style jsx>{`
              .swiper-button-next,
              .swiper-button-prev {
                color: #38a538;
              }
            `}</style>
            <Swiper
              navigation={true}
              autoplay={true}
              pagination={{
                clickable: true,
                el: ".swiper-pagination",
                renderBullet: function (index, className) {
                  return (
                    '<span className="' +
                    className +
                    '" style="background-color: #38A538;"></span>'
                  );
                },
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
              {isLoading && <BannerSkeletonLoader />}
              {slider?.data?.map((slider) => (
                <SwiperSlide key={slider?.id}>
                  <div>
                    <img
                      src={slider?.imageUrl}
                      alt="slider"
                      className="rounded-md"
                    />
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>
        </div>
        <div className="mt-4 hidden md:block">
          <div className="bg-third p-2 w-full rounded-md flex flex-wrap justify-center gap-4 items-center">
            <div className="flex items-center gap-2 usp-item">
              <MdVerifiedUser className="w-6 h-6 text-primary" />
              <p className="my-1">Safe Payments</p>
            </div>
            <div className="flex items-center justify-center gap-2 usp-item">
              <img
                src="/images/delivery.png"
                alt="delivery icon"
                className="w-4 md:w-6"
              />
              <p className="my-1 text-left">Nationwide Delivery</p>
            </div>
            <div className="flex items-center gap-2 usp-item">
              <img
                src="/images/return.png"
                alt="return icon"
                className="w-4 md:w-6"
              />
              <p className="my-1 text-left">Free & Easy Return</p>
            </div>
            <div className="flex items-center gap-2 usp-item">
              <IoIosPricetags className="w-6 h-6 text-primary" />
              <p className="my-1 text-left">Best Price Guaranteed</p>
            </div>
            <div className="flex items-center gap-2 usp-item">
              <img
                src="/images/authentic.png"
                alt="authentic icon"
                className="w-4 md:w-6"
              />
              <p className="my-1 text-left">100% Authentic Products</p>
            </div>
            <div className="flex items-center gap-2">
              <MdVerifiedUser className="w-6 h-6 text-primary" />
              <p className="my-1 text-left">Verified</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
