import React from "react";
import { Link } from "react-router-dom";
import { useOffersListQuery } from "../../redux/feature/slice/service-api";

const Offers = () => {
  const { data } = useOffersListQuery();

  return (
    <>
      <section className="container mx-auto px-2 lg:px-20 my-8 hidden lg:block">
        <h2 className="text-xl md:text-3xl font-bold mb-2 uppercase">
          Best Offers
        </h2>
        <div className="md:flex mx-auto items-center justify-between gap-2">
          {data?.data?.slice(0, 2)?.map((offer) => (
            <div className="my-2" key={offer?.id}>
              <Link to={`/offer-products/${offer?.slug}`}>
                <img
                  src={offer?.imageUrl}
                  alt={offer?.slug}
                  className="rounded-md"
                />
              </Link>
            </div>
          ))}
        </div>
        <div className="flex mx-auto items-center gap-1 justify-between mt-2">
          {data?.data?.length > 2 &&
            data?.data?.slice(2, 5)?.map((offer) => (
              <div className="my-2 md:w-1/3" key={offer?.id}>
                <Link to={`/offer-products/${offer?.slug}`}>
                  <img
                    src={offer?.imageUrl}
                    alt={offer?.slug}
                    className="rounded-md"
                  />
                </Link>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default Offers;
