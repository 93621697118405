import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { SiGooglemessages } from "react-icons/si";

const SmsBot = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container mx-auto px-2 lg:px-20 h-full relative">
      {isOpen && (
        <div className="absolute bottom-20 flex flex-col items-center animate-slide-up">
          <a href="https://wa.me/8801760281618" className="mb-2">
            <img src="/images/whatsapp.png" alt="" className="w-11" />
          </a>
          <a href="https://m.me/mdayubali1996" className="mb-2">
            <img src="/images/messenger.png" alt="" className="w-10" />
          </a>
          <a href="tel:01760281618" className="">
            <img src="/images/telephone-call.png" alt="" className="w-10" />
          </a>
        </div>
      )}
      <div className="mx-0">
        {isOpen ? (
          <button onClick={() => setIsOpen(!isOpen)}>
            <MdCancel className="w-12 h-12 text-base_700" />
          </button>
        ) : (
          <button onClick={() => setIsOpen(!isOpen)}>
            <SiGooglemessages className="w-12 h-12 text-primary" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SmsBot;
