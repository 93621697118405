import React from "react";
import Title from "../components/helmet/title";
import { Link, useParams } from "react-router-dom";
import { useOffersProductsQuery } from "../redux/feature/slice/service-api";

const OfferProducts = () => {
  const { slug } = useParams();

  const { data } = useOffersProductsQuery(slug);

  return (
    <section className="container mx-auto px-2 lg:px-20 my-10 mt-14 md:mt-0">
      <Title title={`${slug}`} content={"This is offer's products page"} />
      <div className="bg-white flex justify-between items-center border-b py-4 pe-5">
        <div></div>
        <div>
          <h2 className="text-black text-lg lg:text-xl font-semibold">
            {slug}
          </h2>
        </div>
        <div className="flex justify-between items-center gap-4">
          <div>Sort by:</div>
          <form className="max-w-sm">
            <select
              id="filter"
              className="rounded-full text-dark focus:ring-primary focus:border-primary block w-full p-2"
            >
              <option value={"Best Match"} className="border-0">
                Best Match
              </option>
              <option value="TS">Top sells</option>
              <option value="NA">Newest arrivals</option>
              <option value="LH">Price low to high</option>
              <option value="HL">Price high to low</option>
            </select>
          </form>
        </div>
      </div>
      <div className="bg-white grid grid-cols-1 md:grid-cols-3 px-2 lg:grid-cols-5 mx-auto items-center p-2 gap-1">
        {data?.data?.products?.length > 0 ? (
          data?.data?.products?.map((p) => (
            <>
              <div
                className="xl:w-60 relative bg-white rounded-lg hover:shadow-lg p-2 mx-auto"
                key={p?.slug}
              >
                <Link
                  to={
                    p?.is_variable > 0
                      ? `/variableProduct/${p?.id}`
                      : `/product/${p?.id}`
                  }
                >
                  <img
                    className="rounded-t-lg md:w-full "
                    src={p?.imageUrl}
                    alt=""
                  />
                  {p.qty === 0 ? (
                    <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center">
                      <p className="text-2xl text-white font-semibold">
                        Sold out
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="p-2">
                    <h5 className="font-medium text-sm tracking-tight text-black">
                      {p?.name.slice(0, 30)}...
                    </h5>
                    {p?.discount_price ? (
                      <p className="font-normal text-primary">
                        {p?.discount_price} TK.
                      </p>
                    ) : (
                      <p className="font-normal text-primary">
                        {p?.regular_price} TK.
                      </p>
                    )}
                    {p?.discount_price && (
                      <div className="flex items-center gap-1">
                        <del className="font-normal text-base_400">
                          {p?.regular_price} TK.
                        </del>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            </>
          ))
        ) : (
          <>
            <p>There is no products</p>
          </>
        )}
      </div>
    </section>
  );
};

export default OfferProducts;
