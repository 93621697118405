import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Title from "../components/helmet/title";
import { useOrderDetailsQuery } from "../redux/feature/slice/service-api";

const SuccessOrder = () => {
  const { orderId } = useParams();
  const { data, isLoading } = useOrderDetailsQuery(orderId);

  const [eventFired, setEventFired] = useState(false);

  const transactionDetails = {
    transactionId: orderId,
    value: data?.data?.order?.price,
    shipping: data?.data?.order?.area,
    items: data?.data?.order?.order_details?.map((order) => ({
      item_name: order.product.name,
      item_id: order.product.id,
      price: order.product.discount_price
        ? order.product.discount_price
        : order.product.regular_price,
      item_brand: "Unknown",
      item_category: order.product.category.name,
      item_variant: "",
      quantity: order.qty,
    })),
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!eventFired && data) {
      //Datalayer Code..
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: transactionDetails.transactionId,
          affiliation: "Banggomart",
          value: transactionDetails.value,
          tax: 0,
          shipping: transactionDetails.shipping,
          currency: "BDT",
          coupon: null,
          items: transactionDetails.items,
        },
      });
      //Datalayer Code..

      setEventFired(true); // Mark event as fired
    }
  }, [data, eventFired, transactionDetails]);

  return (
    <section
      className="container mx-auto px-2 lg:px-20 mt-14 md:mt-0"
      id="falling-flowers"
    >
      <Title
        title={`${orderId}`}
        content={`Confirm your Order. Order Id:${orderId} `}
      />
      <div className="text-center mx-auto my-44 md:my-96 lg:my-80">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <img
              src="/images/check.png"
              alt=""
              className="w-20 lg:w-44 mx-auto"
            />
            <h2 className="text-2xl mt-10">Order Number: {orderId}</h2>
            <p className="text-2xl lg:px-52 mt-4 mx-auto">
              আপনার অর্ডারটি সফলভাবে সম্পন্ন হয়েছে ।আমাদের কল সেন্টার থেকে ফোন
              করে আপনার অর্ডারটি কনফার্ম করা হবে
            </p>
            <div className="mx-auto my-4">
              <Link
                to={"/"}
                className="bg-primary text-white rounded-md px-4 py-3"
              >
                Home back
              </Link>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default SuccessOrder;
