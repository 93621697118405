import React, { useEffect } from "react";
import Title from "../../components/helmet/title";
import { usePaymentQuery } from "../../redux/feature/slice/service-api";
import DOMPurify from "dompurify";

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data } = usePaymentQuery();
  const payment = DOMPurify.sanitize(data?.data?.payment_policy);

  return (
    <section className="container mx-auto px-2 lg:px-20 my-10 lg:my-20 mt-14 md:mt-0">
      <Title title={"Payment"} content={"This is payment policy page"} />
      <h2 className="text-center text-xl lg:text-4xl font-medium">
        Payment Policy
      </h2>
      <div className="my-10">
        <div dangerouslySetInnerHTML={{ __html: payment }} />
      </div>
    </section>
  );
};

export default Payment;
