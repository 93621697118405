import React, { useEffect } from "react";
import Title from "../../components/helmet/title";
import { useTermsConditionQuery } from "../../redux/feature/slice/service-api";
import DOMPurify from "dompurify";

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data } = useTermsConditionQuery();
  const terms_condition = DOMPurify.sanitize(data?.data?.terms_condition);

  return (
    <section className="container mx-auto px-2 lg:px-20 my-10 lg:my-20 mt-14 md:mt-0">
      <Title
        title={"Terms & condition"}
        content={"This is Terms & condition page"}
      />
      <h2 className="text-center text-xl lg:text-4xl font-medium">
        Terms & Condition
      </h2>
      <div className="my-10">
        <div dangerouslySetInnerHTML={{ __html: terms_condition }} />
      </div>
    </section>
  );
};

export default TermsCondition;
