import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetAllProductQuery,
  useOfferTimeQuery,
} from "../../redux/feature/slice/service-api";
import ProductsComponent from "../products/products";

const FlashSale = () => {
  const [end, setEnd] = useState("");
  const [time, setTime] = useState();
  const { isLoading, data: products } = useGetAllProductQuery();
  const { data } = useOfferTimeQuery();
  const offerTime = data?.data?.offer_time[0];

  useEffect(() => {
    const endTime =
      offerTime === undefined ? "" : new Date(offerTime).getTime();
    const timerInterval = setInterval(updateTimer, 1000);

    function updateTimer() {
      const now = new Date().getTime();

      const distance = endTime - now;

      if (distance < 0) {
        clearInterval(timerInterval);
        setEnd("Sale Ended");
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const time = `${days}d ${hours}h : ${minutes}m : ${seconds}s`;
      setTime(time);
    }
  }, [offerTime]);

  return (
    <>
      <section className="container mx-auto px-2 lg:px-20 my-8">
        {/* <!-- Small device --> */}
        <div className="block md:hidden">
          <div className="flex justify-between items-center my-2">
            <div className="text-xl font-bold">Flash Sale</div>
            <Link to="/all-products/all">
              <button className="py-1 md:py-2 px-2 lg:px-4 border-2 border-primary text-primary font-medium uppercase">
                Show More
              </button>
            </Link>
          </div>
          <div className="text-md text-white text-center lg:text-xl bg-primary py-2">
            {time ? time : end}
          </div>
          <div></div>
        </div>
        {/* <!-- Medium and Large device --> */}
        <div className="hidden md:block">
          <h2 className="text-xl md:text-3xl font-bold mb-2 uppercase">
            Flash Sale
          </h2>
          <div className="flex justify-between items-center mt-2 border-b-2 bg-white p-2">
            <div className="flex items-center gap-1 lg:gap-4">
              <h3 className="text-sm lg:text-xl text-primary">On Sale Now</h3>
              <div className="text-sm lg:text-xl text-primary">
                {time ? time : end}
              </div>
            </div>
            <Link to="/all-products/all">
              <button className="py-1 md:py-2 px-2 lg:px-4 border-2 border-primary text-primary font-medium uppercase">
                Show More
              </button>
            </Link>
          </div>
        </div>

        {/* for medium and large device */}
        <div className=" md:block hidden">
          <ProductsComponent
            isLoading={isLoading}
            products={products?.data}
            qty={5}
          />
        </div>
        {/* For only small device */}
        <div className="block md:hidden mt-4">
          <div className="grid grid-rows-2 grid-flow-col grid-cols-2 gap-4">
            <div className=" relative bg-white rounded-lg hover:shadow-lg p-2 mx-auto row-span-2">
              <Link
                to={
                  products?.data[0]?.is_variable >= 0
                    ? `/product/${products?.data[0]?.id}`
                    : `/variableProduct/${products?.data[0]?.id}`
                }
              >
                <img
                  className="rounded-lg w-full "
                  src={products?.data[0]?.imageUrl}
                  alt=""
                />
                {products?.data[0]?.qty === 0 ? (
                  <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center">
                    <p className="text-2xl text-white font-semibold">
                      Sold out
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div className="p-2">
                  <h5 className="font-medium text-sm tracking-tight text-black">
                    {products?.data[0]?.name.slice(0, 20)}
                  </h5>
                </div>
              </Link>
            </div>
            <div className="relative bg-white rounded-lg hover:shadow-lg p-2 mx-auto h-full">
              <Link
                to={
                  products?.data[1]?.is_variable >= 0
                    ? `/product/${products?.data[1]?.id}`
                    : `/variableProduct/${products?.data[1]?.id}`
                }
                className="flex items-center justify-around my-2"
              >
                <div>
                  <img
                    className="rounded-lg w-32 "
                    src={products?.data[1]?.imageUrl}
                    alt=""
                  />
                  {products?.data[1]?.qty === 0 ? (
                    <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center">
                      <p className="text-2xl text-white font-semibold">
                        Sold out
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="p-2">
                  <h5 className="font-medium text-sm tracking-tight text-black">
                    {products?.data[1]?.name.slice(0, 20)}
                  </h5>
                </div>
              </Link>
            </div>
            <div className="relative bg-white rounded-lg hover:shadow-lg p-2 mx-auto h-full">
              <Link
                to={
                  products?.data[2]?.is_variable >= 0
                    ? `/product/${products?.data[2]?.id}`
                    : `/variableProduct/${products?.data[2]?.id}`
                }
                className="flex items-center justify-around my-2"
              >
                <div>
                  <img
                    className="rounded-lg w-32 "
                    src={products?.data[2]?.imageUrl}
                    alt=""
                  />
                  {products?.data[2]?.qty === 0 ? (
                    <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center">
                      <p className="text-2xl text-white font-semibold">
                        Sold out
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="px-2">
                  <h5 className="font-medium text-sm tracking-tight text-black">
                    {products?.data[2]?.name.slice(0, 20)}
                  </h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FlashSale;
