import React from "react";
import { Helmet } from "react-helmet-async";

const Title = ({ title, content }) => {
  return (
    <div>
      <Helmet>
        <title>{title} - Kicksha</title>
        <meta name={title} content={content} />
        <link rel="canonical" href="http://kicksha.com" />
      </Helmet>
    </div>
  );
};

export default Title;
