import React, { useEffect } from "react";
import Banner from "../components/home/banner";
import FlashSale from "../components/home/flash-sale";
// import TopBrands from "../components/home/top-brands";
import Offers from "../components/home/offers";
import Categories from "../components/home/categories";
import JustForYou from "../components/home/just-for";
import Title from "../components/helmet/title";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Title title={"Home"} content={"Online Shopping in Bangladesh"} />
      {/* Banner */}
      <Banner />

      {/* <!-- Flash Sale--> */}
      <FlashSale />

      {/* Top brands */}
      {/* <TopBrands /> */}

      {/* Offers */}
      <Offers />

      {/* Categories */}
      <Categories />

      {/* Just for you */}
      <JustForYou />
    </div>
  );
};

export default Home;
