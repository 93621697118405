import React, { useEffect, useState } from "react";
import {
  useGetAllCategoriesQuery,
  useGetAllProductQuery,
  useGetCategoryProductsQuery,
} from "../redux/feature/slice/service-api";
import { useParams } from "react-router-dom";
import CategoryTabsSkeleton from "../components/loading/categorySkeleton";
import Title from "../components/helmet/title";
import ProductsComponent from "../components/products/products";

const FilterProducts = () => {
  const { name, id } = useParams();

  const [tab, setTab] = useState(name);
  const [catId, setCatId] = useState();

  // all categories name retrieve
  const { data: categories, isLoading } = useGetAllCategoriesQuery();
  // all products retrieve
  const { data: products, isLoading: productsLoading } =
    useGetAllProductQuery();
  //  retrieve specific category products
  const {
    data: catProducts,
    refetch,
    isLoading: categoriesLoading,
  } = useGetCategoryProductsQuery(catId);

  useEffect(() => {
    window.scrollTo(0, 0);

    // if name not equal all then setCatId
    if (name !== "all") {
      setCatId(id);
    }
  }, [name, id]);

  const tabHandler = (name, id) => {
    setTab(name);

    if (name !== "all") {
      setCatId(id);
      refetch();
    }
  };

  return (
    <>
      {/* <!--Filter products Section --> */}
      <section className="container mx-auto px-2 lg:px-20 my-10 mt-14 md:mt-0">
        <Title title={`${tab}`} content={"This is all products page"} />
        <div className="bg-white flex justify-end items-center border-b py-4 pe-5">
          <div className="flex justify-between items-center gap-4">
            <div>Sort by:</div>
            <form className="max-w-sm">
              <select
                id="filter"
                className="rounded-full text-dark focus:ring-primary focus:border-primary block w-full p-2"
              >
                <option value={"Best Match"} className="border-0">
                  Best Match
                </option>
                <option value="TS">Top sells</option>
                <option value="NA">Newest arrivals</option>
                <option value="LH">Price low to high</option>
                <option value="HL">Price high to low</option>
              </select>
            </form>
          </div>
        </div>
        <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
          {isLoading ? (
            <CategoryTabsSkeleton />
          ) : (
            <>
              {/* all products tab */}
              <div onClick={() => tabHandler("all")}>
                <button
                  id="all"
                  className={`${
                    tab === "all" ? "text-primary" : "text-black"
                  } ""`}
                >
                  All
                </button>
              </div>
              {/* categories tabs */}
              {categories?.data?.map((c) => (
                <div key={c?.id} onClick={() => tabHandler(c?.name, c?.id)}>
                  <button
                    id={c?.name}
                    className={`${
                      tab === c?.name ? "text-primary" : "text-black"
                    } ""`}
                  >
                    {c?.name}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>

        {/* all products */}
        {tab === "all" && (
          <ProductsComponent
            isLoading={productsLoading}
            products={products?.data}
          />
        )}
        {/* categories wise products showcase */}
        {categories?.data?.map(
          (c) =>
            tab === c?.name && (
              <ProductsComponent
                isLoading={categoriesLoading}
                products={catProducts}
              />
            )
        )}
      </section>
    </>
  );
};

export default FilterProducts;
