import React, { useEffect } from "react";
import Title from "../../components/helmet/title";
import { usePrivacyQuery } from "../../redux/feature/slice/service-api";
import DOMPurify from "dompurify";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = usePrivacyQuery();
  const privacy = DOMPurify.sanitize(data?.data?.privacy_policy);

  return (
    <section className="container mx-auto px-2 lg:px-20 my-10 lg:my-20 mt-14 md:mt-0">
      <Title title={"Privacy"} content={"This is privacy policy page"} />
      <h2 className="text-center text-xl lg:text-4xl font-medium">
        Privacy Policy
      </h2>
      <div className="my-10">
        <div dangerouslySetInnerHTML={{ __html: privacy }} />
      </div>
    </section>
  );
};

export default Privacy;
