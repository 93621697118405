import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  useAddToCartMutation,
  useGetCartProductsQuery,
  useGetTotalCartQuery,
} from "../redux/feature/slice/service-api";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const QuickOrder = ({
  data,
  id,
  productPrice,
  discount_price,
  regular_price,
}) => {
  const navigate = useNavigate();
  const [ip, setIp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIp("127.0.0.1");
      }
    };
    fetchIpAddress();
  }, [id]);
  const { refetch } = useGetTotalCartQuery(ip);
  const { refetch: refetchCart } = useGetCartProductsQuery(ip);

  const [addToCart] = useAddToCartMutation();

  const handleQuickOrder = async (path) => {
    const price = productPrice
      ? productPrice
      : discount_price
      ? discount_price
      : regular_price;
    const qty = data.qty;
    const size = data?.sizes;
    const color = data?.colors;

    const ip_address = ip;
    const payload = {
      ip_address,
      qty,
      price,
      color,
      size,
    };

    try {
      const response = await addToCart({ id, payload }).unwrap();
      swal("Good job!", response.message, "success");
      navigate(path);
      refetch();
      refetchCart();
    } catch (error) {
      console.error("Error adding to cart:", error);
      swal("Error", "Failed to add to cart", "error");
    }
  };
  return (
    <div>
      <div type="button">
        <button
          onClick={() => handleQuickOrder("/checkout")}
          className="bg-primary w-full lg:px-4 py-2 rounded-md text-white"
        >
          Order Now
        </button>
      </div>
    </div>
  );
};

export default QuickOrder;
