import React from "react";

const CategorySkeletonLoader = () => {
  return (
    <>
      {/* this is home page categories skeleton */}
      <div className="w-32 md:w-52 md:h-44 bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-32 rounded-md"></div>
        <div className=" bg-slate-200 w-full h-4 rounded-md my-2"></div>
      </div>
      <div className="w-32 md:w-52 md:h-44 bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-32 rounded-md"></div>
        <div className=" bg-slate-200 w-full h-4 rounded-md my-2"></div>
      </div>
      <div className="w-32 md:w-52 md:h-44 bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-32 rounded-md"></div>
        <div className=" bg-slate-200 w-full h-4 rounded-md my-2"></div>
      </div>
      <div className="w-32 md:w-52 md:h-44 bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-32 rounded-md"></div>
        <div className=" bg-slate-200 w-full h-4 rounded-md my-2"></div>
      </div>
      <div className="w-32 md:w-52 md:h-44 bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-32 rounded-md"></div>
        <div className=" bg-slate-200 w-full h-4 rounded-md my-2"></div>
      </div>
      <div className="w-32 md:w-52 md:h-44 bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-32 rounded-md"></div>
        <div className=" bg-slate-200 w-full h-4 rounded-md my-2"></div>
      </div>
    </>
  );
};

export default CategorySkeletonLoader;
