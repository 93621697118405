import React, { useState } from "react";
import { useGetAllProductQuery } from "../../redux/feature/slice/service-api";
import ProductsComponent from "../products/products";

const JustForYou = () => {
  let [qty, setQty] = useState(10);
  const { isLoading, data: products } = useGetAllProductQuery();

  const LoadHandler = () => {
    setQty(qty + 10);
  };

  return (
    <>
      <section className="container mx-auto px-2 lg:px-20 mb-8">
        <h2 className="text-xl md:text-3xl font-bold mb-2 uppercase">
          Just For You
        </h2>
        <ProductsComponent
          products={products?.data}
          qty={qty}
          isLoading={isLoading}
        />
        <div className="text-center my-4">
          <button
            onClick={() => LoadHandler(10)}
            className="px-12 md:px-20 py-2 font-semibold border border-primary text-primary"
          >
            Load More
          </button>
        </div>
      </section>
    </>
  );
};

export default JustForYou;
