import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../components/helmet/title";

const Brands = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // only for small device brands page
  return (
    <section className="container mx-auto px-2 lg:px-20 my-8 mt-14 md:mt-0">
      <Title title={"Brands"} content={"This is brands page "} />
      <div
        className="bg-white grid grid-cols-3 md:grid-cols-5 mx-auto justify-center lg:grid-cols-5 xl:grid-cols-8"
        id="categories"
      >
        <div className="w-34 lg:w-46 p-4 border hover:shadow-lg">
          <Link to="/pages/brand/brand_product.html">
            <div className="mx-auto flex justify-center">
              <img src="/images/chino.jpg" alt="" className="w-32 h-28 my-2" />
            </div>
            <p className="text-center">Category Name</p>
          </Link>
        </div>
        <div className="w-34 lg:w-46 p-4 border hover:shadow-lg">
          <Link to="/pages/brand/brand_product.html">
            <div className="mx-auto flex justify-center">
              <img src="/images/chino.jpg" alt="" className="w-32 h-28 my-2" />
            </div>
            <p className="text-center">Category Name</p>
          </Link>
        </div>
        <div className="w-34 lg:w-46 p-4 border hover:shadow-lg">
          <Link to="/pages/brand/brand_product.html">
            <div className="mx-auto flex justify-center">
              <img src="/images/chino.jpg" alt="" className="w-32 h-28 my-2" />
            </div>
            <p className="text-center">Category Name</p>
          </Link>
        </div>
        <div className="w-34 lg:w-46 p-4 border hover:shadow-lg">
          <Link to="/pages/brand/brand_product.html">
            <div className="mx-auto flex justify-center">
              <img src="/images/chino.jpg" alt="" className="w-32 h-28 my-2" />
            </div>
            <p className="text-center">Category Name</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Brands;
