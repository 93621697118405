import React from "react";

const JustLoadingSpinner = () => {
  return (
    <>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
      <div className="w-full h-fit bg-base_400 rounded-lg p-2 animate-pulse mx-auto">
        <div className=" bg-slate-200 w-full h-40 rounded-t-md"></div>
        <div className="py-2">
          <div className="rounded-md bg-slate-200 h-5 w-full my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
          <div className="rounded-md bg-slate-200 h-5 w-10 my-2"></div>
        </div>
      </div>
    </>
  );
};

export default JustLoadingSpinner;
