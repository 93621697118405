import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "flowbite";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import router from "./routes/routes";
import { Provider } from "react-redux";
import { Store } from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="mx-auto">
    <HelmetProvider>
      <React.StrictMode>
        <Provider store={Store}>
          <RouterProvider router={router} />
        </Provider>
      </React.StrictMode>
    </HelmetProvider>
  </div>
);

reportWebVitals();

