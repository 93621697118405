import React from "react";
import { Link } from "react-router-dom";
import JustLoadingSpinner from "../loading/justLoadingSpinner";

const ProductsComponent = ({ products, qty, isLoading }) => {
  return (
    <div className="bg-white py-4 grid grid-cols-2 md:grid-cols-3 mx-auto justify-center lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
      {isLoading && <JustLoadingSpinner />}
      {qty
        ? products?.slice(0, qty).map((p) => (
            <div
              className="xl:w-60 relative bg-white rounded-lg hover:shadow-lg p-2 mx-auto"
              key={p?.slug}
            >
              <Link
                to={
                  p?.is_variable > 0
                    ? `/variableProduct/${p?.id}`
                    : `/product/${p?.id}`
                }
              >
                <img
                  className="rounded-t-lg md:w-full "
                  src={p?.imageUrl}
                  alt=""
                />
                {p.qty === 0 ? (
                  <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center">
                    <p className="text-2xl text-white font-semibold">
                      Sold out
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div className="p-2">
                  <h5 className="font-medium text-sm tracking-tight text-black">
                    {p?.name.slice(0, 30)}...
                  </h5>
                  {p?.discount_price ? (
                    <p className="font-normal text-primary">
                      {p?.discount_price} TK.
                    </p>
                  ) : (
                    <p className="font-normal text-primary">
                      {p?.regular_price} TK.
                    </p>
                  )}
                  {p?.discount_price && (
                    <div className="flex items-center gap-1">
                      <del className="font-normal text-base_400">
                        {p?.regular_price} TK.
                      </del>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          ))
        : products?.map((p) => (
            <div
              className="xl:w-60 relative bg-white rounded-lg hover:shadow-lg p-2 mx-auto"
              key={p?.slug}
            >
              <Link
                to={
                  p?.is_variable > 0
                    ? `/variableProduct/${p?.id}`
                    : `/product/${p?.id}`
                }
              >
                <img
                  className="rounded-t-lg md:w-full "
                  src={p?.imageUrl}
                  alt=""
                />
                {p.qty === 0 ? (
                  <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center">
                    <p className="text-2xl text-white font-semibold">
                      Sold out
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div className="p-2">
                  <h5 className="font-medium text-sm tracking-tight text-black">
                    {p?.name.slice(0, 30)}...
                  </h5>
                  {p?.discount_price ? (
                    <p className="font-normal text-primary">
                      {p?.discount_price} TK.
                    </p>
                  ) : (
                    <p className="font-normal text-primary">
                      {p?.regular_price} TK.
                    </p>
                  )}
                  {p?.discount_price && (
                    <div className="flex items-center gap-1">
                      <del className="font-normal text-base_400">
                        {p?.regular_price} TK.
                      </del>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          ))}
    </div>
  );
};

export default ProductsComponent;
