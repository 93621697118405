import React, { useEffect } from "react";
import Title from "../components/helmet/title";
import { useAboutUsQuery } from "../redux/feature/slice/service-api";
import DOMPurify from "dompurify";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = useAboutUsQuery();
  const about = DOMPurify.sanitize(data?.data?.about);
  return (
    // <!-- contact us section -->
    <section className="container mx-auto px-2 lg:px-20 my-10 lg:my-20 mt-14 md:mt-0">
      <Title title={"About us"} content={"This is About us Page "} />
      <h2 className="text-center text-xl lg:text-5xl font-medium my-10 lg:my-20">
        About Us
      </h2>
      <div className="my-10 text-center">
        <div dangerouslySetInnerHTML={{ __html: about }} className="mx-auto" />
      </div>
    </section>
  );
};

export default About;
