import React, { useEffect, useState } from "react";
import { FaHome, FaRegTrashAlt } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { IoGrid } from "react-icons/io5";
// import { TbBrandArc } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import {
  useCartDeleteMutation,
  useGetAllCategoriesQuery,
  useGetCartProductsQuery,
  useGetGeneralDataQuery,
  useGetTotalCartQuery,
} from "../redux/feature/slice/service-api";
import axios from "axios";
import swal from "sweetalert";

const Navbar = () => {
  const [ip, setIp] = useState();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const navigate = useNavigate();
  const { data: Categories } = useGetAllCategoriesQuery();
  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const showDropdown = (categoryName) => {
    setVisibleDropdown(categoryName);
  };

  const hideDropdown = () => {
    setVisibleDropdown(null);
  };

  window.addEventListener("scroll", function () {
    const scrollTop = window.scrollY;
    const fixedNavbar = document.getElementById("fixed-navbar");

    if (scrollTop >= 100) {
      fixedNavbar.classList.remove("hidden");
    } else {
      fixedNavbar.classList.add("hidden");
    }
  });
  useEffect(() => {
    // Fetch the IP address dynamically
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIp("127.0.0.1");
      }
    };
    fetchIpAddress();
  }, []);

  const { data, refetch } = useGetTotalCartQuery(ip);
  const { data: generalData, isLoading } = useGetGeneralDataQuery();
  const { data: cartProducts, refetch: refetchCart } =
    useGetCartProductsQuery(ip);

  const total = data?.data;

  const [deleteCart] = useCartDeleteMutation();

  const deleteHandler = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id);
          swal("Good job!", res?.data?.message, "success");
          refetch();
          refetchCart();
        } catch (error) {
          console.log(error);
        }
      } else {
        swal("Your product is safe!");
      }
    });
  };

  const formHandler = (e) => {
    e.preventDefault();

    navigate(`/search-products/${e.target.searchName.value}`);
    e.target.searchName.value = "";
  };
  return (
    <>
      {/* medium & large default Navbar */}
      <div className="bg-primary hidden md:block" id="default-navbar">
        <nav className="container mx-auto py-4 lg:px-20 px-1 flex justify-between items-center gap-4">
          <Link to="/">
            {isLoading && (
              <div className="w-32 h-10 bg-base rounded-full animate-pulse"></div>
            )}
            {generalData?.generalData?.logo_url && (
              <img
                src={generalData?.generalData?.logo_url}
                alt="Logo"
                className="w-20 md:w-32"
              />
            )}
          </Link>
          <form className="mx-auto" onSubmit={formHandler}>
            <label
              htmlFor="searchName"
              className="mb-2 text-sm font-medium text-dark sr-only"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
              <input
                type="search"
                name="searchName"
                className="block text-black w-[250px] md:w-[300px] lg:w-[600px] 2xl:w-[900px] lg:p-4 p-2 rounded-lg text-xs lg:text-sm border-0 bg-white focus:ring-secondary focus:border-secondary"
                placeholder="Search now..."
                required
              />
              <button
                type="submit" // Ensure the button type is set to submit
                className="text-primary absolute end-2.5 lg:bottom-2.5 bottom-1 bg-secondary font-medium rounded-lg lg:px-4 lg:py-2 p-2"
              >
                <svg
                  className="lg:w-4 lg:h-4 w-2 h-2 text-primary"
                  aria-hidden={true}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </button>
            </div>
          </form>
          <div className="flex justify-center items-center text-white lg:gap-6 gap-2 text-xs md:text-lg">
            {/* <div className="flex items-center lg:gap-2 gap-1">
              <li className="my-2">
                <Link to="/pages/auth/login.html">
                  <span>
                    <i className="fa-regular fa-user w-4 h-4"></i>{" "}
                  </span>
                  Login
                </Link>
              </li>
            </div> */}

            {/* <div>
              <button
                id="dropdownRadioButton"
                data-dropdown-toggle="dropdownDefaultRadioFixed"
                data-dropdown-trigger="hover"
                className="text-white font-medium rounded-lg text-sm md:text-lg py-2.5 text-center inline-flex items-center gap-1"
                type="button"
              >
                EN
                <svg
                  className="w-2.5 h-2.5 ms-1 lg:ms-3"
                  aria-hidden={true}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              {/* <!-- Dropdown menu --> */}
            {/* <div
              id="dropdownDefaultRadioFixed"
              className="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow"
            >
              <ul
                className="p-3 space-y-3 text-sm text-base_700"
                aria-labelledby="dropdownRadioButton"
              >
                <li className="my-2">
                  <div className="flex items-center">
                    <input
                      id="default-radio-3"
                      type="radio"
                      value=""
                      name="default-radio"
                      className="w-4 h-4 text-blue-600 bg-white border-base_300 focus:ring-blue-500"
                    />
                    <label
                      htmlFor="default-radio-3"
                      className="ms-2 text-sm md:text-lg font-medium text-dark"
                    >
                      BN / Bangla
                    </label>
                  </div>
                </li>
                <li className="my-2">
                  <div className="flex items-center">
                    <input
                      defaultChecked
                      id="default-radio-2"
                      type="radio"
                      value=""
                      name="default-radio"
                      className="w-4 h-4 text-blue-600 bg-white border-base_300 focus:ring-blue-500"
                    />
                    <label
                      htmlFor="default-radio-2"
                      className="ms-2 text-sm md:text-lg font-medium text-dark"
                    >
                      EN / English
                    </label>
                  </div>
                </li>
              </ul>
            </div> */}
            {/* </div> */}
            {/* <div>
              <Link to="h#">
                <FaHeart />
              </Link>
            </div> */}
            {/* <!-- Cart dropdown --> */}
            <div>
              <li className="my-2">
                {/* <!-- cart dropdown button --> */}
                <button
                  onMouseEnter={() => setIsCartOpen(true)}
                  className="relative"
                  id="doubleDropdownButton"
                  data-dropdown-toggle="dropdownCart1"
                  data-dropdown-trigger="hover"
                >
                  <FaCartPlus className="w-6 h-6" />
                  <small className="absolute top-0 right-4 translate-x-1/2 -translate-y-1/2 bg-secondary px-1 py-0.5 text-primary text-xs font-medium rounded-full">
                    {total ? total : "0"}
                  </small>
                </button>

                {/* <!-- cart dropdown menu --> */}
                {isCartOpen && (
                  <div
                    onMouseLeave={() => setIsCartOpen(false)}
                    id="dropdownCart1"
                    className="z-50 w-72 h-fit overscroll-y-auto p-4 text-black bg-white divide-y divide-gray-100 rounded-lg shadow top-20 fixed right-0 xl:right-32"
                  >
                    <div>
                      <div className="overflow-y-scroll h-52">
                        {cartProducts?.data?.carts?.length > 0 ? (
                          <>
                            {cartProducts?.data?.carts.map((p) => (
                              <div
                                className="flex justify-between items-center gap-4 mx-auto my-4 "
                                key={p?.id}
                              >
                                <div>
                                  <img
                                    src={p?.product?.imageUrl}
                                    alt=""
                                    className="w-52"
                                  />
                                </div>
                                <div className="">
                                  <h3>{p?.product?.name}</h3>
                                  <p className="text-primary font-semibold">
                                    {p?.price}TK.
                                  </p>
                                </div>
                                <div>
                                  <button
                                    onClick={() => deleteHandler(p?.id)}
                                    className="text-primary active:text-danger"
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          "You haven't added any products"
                        )}
                      </div>
                      <hr className="border border-base_300" />
                      <div>
                        <div className="flex justify-between items-center mt-2">
                          <p className="font-semibold">Total</p>
                          <p>{cartProducts?.data?.subTotal} TK.</p>
                        </div>
                        <div className="flex justify-between items-center mx-auto my-6">
                          <Link
                            to="/view-cart"
                            className="px-4 py-2 border border-primary rounded-md font-medium"
                          >
                            View Cart
                          </Link>

                          <Link
                            to="/checkout"
                            className="px-4 py-2 bg-primary text-base rounded-md font-medium"
                          >
                            Checkout
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </div>
          </div>
        </nav>
      </div>

      {/* medium and large device fixed navbar */}
      <nav className="hidden md:block">
        <div
          className="bg-primary fixed w-full z-50 top-0 start-0 hidden"
          id="fixed-navbar"
        >
          <div className="container mx-auto py-4 lg:px-20 px-1 flex justify-between items-center gap-1 lg:gap-2">
            <Link to="/">
              {isLoading && (
                <div className="w-32 h-10 bg-base rounded-full animate-pulse"></div>
              )}
              {generalData?.generalData?.logo_url && (
                <img
                  src={generalData?.generalData?.logo_url}
                  alt="Logo"
                  className="w-20 md:w-32"
                />
              )}
            </Link>
            <div>
              <button
                onMouseEnter={() => setIsCategoryOpen(true)}
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                className="text-white font-medium rounded-lg text-xs md:text-lg px-5 py-2.5 text-center inline-flex items-center"
                type="button"
              >
                Category
                <svg
                  className="w-2.5 h-2.5 ms-1 lg:ms-3"
                  aria-hidden={true}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              {/* <!-- Dropdown menu --> */}
              <div onMouseLeave={() => setIsCategoryOpen(false)}>
                {isCategoryOpen && (
                  <div
                    id="dropdownHover"
                    className="z-10 text-black font-normal bg-white divide-y divide-gray-100 rounded-lg shadow  w-52 h-fit fixed"
                    onMouseLeave={hideDropdown}
                  >
                    <ul className="px-4">
                      {/* <!-- dropdown button --> */}
                      {Categories?.data?.map((c) => (
                        <div
                          key={c?.id}
                          onMouseEnter={() => showDropdown(c?.name)}
                        >
                          <button
                            id="dropdownRightButton"
                            data-dropdown-trigger="hover"
                            data-dropdown-toggle={`${c?.name}`}
                            data-dropdown-placement="right"
                            className="text-xs md:text-lg flex gap-4 items-center text-left relative my-2 hover:text-primary"
                          >
                            <span>
                              <img
                                src={c?.imageUrl}
                                alt={c?.name}
                                className="w-10"
                              />
                            </span>
                            <p className="w-32">{c?.name}</p>
                            <svg
                              className="w-2.5 h-2.5"
                              aria-hidden={true}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 6 10"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 9 4-4-4-4"
                              />
                            </svg>
                          </button>
                          {/* <!-- dropdown menu --> */}
                          <div
                            id={`${c?.name}`}
                            className={`z-10 ${
                              visibleDropdown === c?.name ? "block" : "hidden"
                            } bg-white rounded-lg shadow w-32 lg:w-60 md:h-full absolute left-[200px] top-0 ml-2 px-2`}
                          >
                            <ul
                              className="py-2 text-base_700 px-4"
                              aria-labelledby="dropdownRightButton"
                            >
                              {c?.subcategories?.map((sub) => (
                                <li key={sub.id} className="my-2">
                                  <Link
                                    to={`/categories-products/${sub?.name}/${sub.id}`}
                                  >
                                    {sub.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <form className="mx-auto" onSubmit={formHandler}>
              <label
                htmlFor="searchName"
                className="mb-2 text-sm font-medium text-dark sr-only"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                <input
                  type="search"
                  name="searchName"
                  className="block text-black w-[250px] md:w-[300px] lg:w-[600px] 2xl:w-[900px] lg:p-4 p-2 rounded-lg text-xs lg:text-sm border-0 bg-white focus:ring-secondary focus:border-secondary"
                  placeholder="Search now..."
                  required
                />
                <button
                  type="submit" // Ensure the button type is set to submit
                  className="text-primary absolute end-2.5 lg:bottom-2.5 bottom-1 bg-secondary font-medium rounded-lg lg:px-4 lg:py-2 p-2"
                >
                  <svg
                    className="lg:w-4 lg:h-4 w-2 h-2 text-primary"
                    aria-hidden={true}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button>
              </div>
            </form>
            <div className="flex justify-between items-center text-white md:gap-6 gap-2 text-xs md:text-lg">
              <div className="">
                <li className="my-2">
                  <Link to="/pages/auth/login.html">
                    <i className="fa-regular fa-user w-2 h-2 lg:w-4 lg:h-4"></i>
                  </Link>
                </li>
              </div>
              {/* <button>Login</button>
              <div>
                <button
                  id="dropdownRadioButton"
                  data-dropdown-toggle="dropdownDefaultRadio"
                  className="text-white font-medium rounded-lg text-sm md:text-lg py-2.5 text-center inline-flex items-center gap-1"
                  type="button"
                >
                  <i className="fa-solid fa-earth-americas"></i> EN
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden={true}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {/* <!-- Dropdown menu --> */}
              {/* <div
                id="dropdownDefaultRadio"
                className="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow"
              >
                <ul
                  className="p-3 space-y-3 text-sm md:text-lg text-base_700"
                  aria-labelledby="dropdownRadioButton"
                >
                  <li className="my-2">
                    <div className="flex items-center">
                      <input
                        id="default-radio-3"
                        type="radio"
                        value=""
                        name="default-radio"
                        className="w-4 h-4 text-blue-600 bg-white border-base_300 focus:ring-blue-500"
                      />
                      <label
                        htmlFor="default-radio-3"
                        className="ms-2 text-sm md:text-lg font-medium text-dark"
                      >
                        BN / Bangla
                      </label>
                    </div>
                  </li>
                  <li className="my-2">
                    <div className="flex items-center">
                      <input
                        defaultChecked
                        id="default-radio-2"
                        type="radio"
                        value=""
                        name="default-radio"
                        className="w-4 h-4 text-blue-600 bg-white border-base_300 focus:ring-blue-500"
                      />
                      <label
                        htmlFor="default-radio-2"
                        className="ms-2 text-sm md:text-lg font-medium text-dark"
                      >
                        EN / English
                      </label>
                    </div>
                  </li>
                </ul>
              </div> */}
              {/* </div>
              <div>
                <Link to="#">
                  <FaHeart />
                </Link> */}
              {/* </div>  */}
              {/* <!-- Cart dropdown --> */}
              <div>
                <li className="my-2">
                  {/* <!-- cart dropdown button --> */}
                  <button
                    onMouseEnter={() => setIsCartOpen(true)}
                    className="relative"
                    id="doubleDropdownButton"
                    data-dropdown-toggle="dropdownCart1"
                    data-dropdown-trigger="hover"
                  >
                    <FaCartPlus className="w-6 h-6" />
                    <small className="absolute top-0 right-4 translate-x-1/2 -translate-y-1/2 bg-secondary px-1 py-0.5 text-primary text-xs font-medium rounded-full">
                      {total ? total : "0"}
                    </small>
                  </button>

                  {/* <!-- cart dropdown menu --> */}
                  {isCartOpen && (
                    <div
                      onMouseLeave={() => setIsCartOpen(false)}
                      id="dropdownCart1"
                      className="z-50 w-72 h-fit overscroll-y-auto p-4 text-black bg-white divide-y divide-gray-100 rounded-lg shadow top-20 fixed right-0 xl:right-32"
                    >
                      <div>
                        <div className="overflow-y-scroll h-52">
                          {cartProducts?.data?.carts?.length > 0 ? (
                            <>
                              {cartProducts?.data?.carts.map((p) => (
                                <div
                                  className="flex justify-between items-center gap-4 mx-auto my-4 "
                                  key={p?.id}
                                >
                                  <div>
                                    <img
                                      src={p?.product?.imageUrl}
                                      alt=""
                                      className="w-52"
                                    />
                                  </div>
                                  <div className="">
                                    <h3>{p?.product?.name}</h3>
                                    <p className="text-primary font-semibold">
                                      {p?.price}TK.
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => deleteHandler(p?.id)}
                                      className="text-primary active:text-danger"
                                    >
                                      <FaRegTrashAlt />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            "You haven't added any products"
                          )}
                        </div>
                        <hr className="border border-base_300" />
                        <div>
                          <div className="flex justify-between items-center mt-2">
                            <p className="font-semibold">Total</p>
                            <p>{cartProducts?.data?.subTotal} TK.</p>
                          </div>
                          <div className="flex justify-between items-center mx-auto my-6">
                            <Link
                              to="/view-cart"
                              className="px-4 py-2 border border-primary rounded-md font-medium"
                            >
                              View Cart
                            </Link>

                            <Link
                              to="/checkout"
                              className="px-4 py-2 bg-primary text-base rounded-md font-medium"
                            >
                              Checkout
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* <!-- Small device default navbar --> */}
      <nav className="container block md:hidden mx-auto px-2 lg:px-20 bg-primary fixed top-0 z-20 left-0 right-0">
        <div className="flex justify-between py-2 items-center mx-auto">
          <div>
            <Link to="/">
              {isLoading && (
                <div className="w-20 h-10 bg-base rounded-full animate-pulse"></div>
              )}
              {generalData?.generalData?.logo_url && (
                <img
                  src={generalData?.generalData?.logo_url}
                  alt="Logo"
                  className="w-20"
                />
              )}
            </Link>
          </div>
          <div>
            <form className="mx-auto" onSubmit={formHandler}>
              <label
                htmlFor="searchName"
                className="mb-2 text-sm font-medium text-dark sr-only"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                <input
                  type="search"
                  name="searchName"
                  className="block text-black w-[250px] md:w-[300px] lg:w-[500px] xl:w-[950px] lg:p-4 p-2 rounded-lg text-xs lg:text-sm border-0 bg-white focus:ring-secondary focus:border-secondary"
                  placeholder="Search now..."
                  required
                />
                <button
                  type="submit" // Ensure the button type is set to submit
                  className="text-primary absolute end-2.5 lg:bottom-2.5 bottom-1 bg-secondary font-medium rounded-lg lg:px-4 lg:py-2 p-2"
                >
                  <svg
                    className="lg:w-4 lg:h-4 w-2 h-2 text-primary"
                    aria-hidden={true}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </nav>

      {/* <!-- small device fixed navbar bottom--> */}
      <nav className="container mx-auto block md:hidden fixed w-full z-20 bottom-0 start-0 bg-white py-2 border-t-2">
        <div className="flex justify-around items-center text-black">
          <li className="my-2">
            <Link to="/">
              <div className="text-center">
                <FaHome className="mx-auto" />
                <p className="text-xs">Home</p>
              </div>
            </Link>
          </li>
          {/* <li className="my-2">
            <Link to="/brands">
              <div className="text-center">
                <TbBrandArc className="mx-auto" />
                <p className="text-xs">Brands</p>
              </div>
            </Link>
          </li> */}
          <li className="my-2">
            <Link to="/categories">
              <div className="text-center">
                <IoGrid className="mx-auto" />
                <p className="text-xs">Categories</p>
              </div>
            </Link>
          </li>
          <li className="my-2">
            <Link to="/view-cart">
              <div className="text-center">
                <FaCartPlus className="mx-auto"></FaCartPlus>
                <p className="text-xs">Cart</p>
              </div>
            </Link>
          </li>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
