import React from "react";
import { Link } from "react-router-dom";
import { useGetAllCategoriesQuery } from "../../redux/feature/slice/service-api";
import CategorySkeletonLoader from "../loading/categorySkeletonLoader";

const Categories = () => {
  const { isLoading, data: Categories } = useGetAllCategoriesQuery();

  return (
    <section className="container mx-auto px-2 lg:px-20 mb-8 hidden md:block">
      <h2 className="text-xl md:text-3xl font-bold mb-2 uppercase">
        Categories
      </h2>
      <div className="bg-white grid grid-cols-3 mx-auto justify-center lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        {isLoading && <CategorySkeletonLoader />}
        {Categories?.data?.map((category) => (
          <div key={category?.id} className="w-34 p-4 border hover:shadow-lg">
            <Link to={`/all-products/${category?.name}/${category?.id}`}>
              <div className="mx-auto flex justify-center">
                <img
                  src={category?.imageUrl}
                  alt={category?.name}
                  className="w-32 h-28 my-2"
                />
              </div>
              <p className="text-center">{category?.name}</p>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Categories;
