import React from "react";

const CategoriesDropdownSkeletonLoader = () => {
  return (
    // dropdown categories skeleton
    <div className="rounded-md">
      <div className="flex gap-2 items-center p-2">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
      <div className="flex gap-2 items-center p-2 ">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
      <div className="flex gap-2 items-center p-2 ">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
      <div className="flex gap-2 items-center p-2 ">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
      <div className="flex gap-2 items-center p-2 ">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
      <div className="flex gap-2 items-center p-2 ">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
      <div className="flex gap-2 items-center p-2 ">
        <div className="w-8 h-8 rounded-md bg-base_200"></div>
        <div className="w-32 h-6 bg-base_200 rounded-md"></div>
      </div>
    </div>
  );
};

export default CategoriesDropdownSkeletonLoader;
