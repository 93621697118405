import React from "react";

const CategoryTabsSkeleton = () => {
  return (
    <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-base_400 border-b text-lg">
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
      <div className="w-16 h-6 bg-base_200 rounded-md"></div>
    </div>
  );
};

export default CategoryTabsSkeleton;
