import React from "react";
import Navbar from "../components/navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import SmsBot from "../components/smsbot";

const MainLayout = () => {
  return (
    <div className="">
      <Navbar />
      <Outlet />
      <div className="fixed bottom-20 right-0 md:right-2 lg:right-0 xl:right-0 2xl:right-[calc(10%-140px)] z-50">
        <SmsBot />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
